<template>
  <pages-badge h1="Klimatyzatory" bg="hero.jpg">
    <template v-slot:default>
      <section-badge
        h1="Jeden system wiele funkcji"
        h2="Nasza firma specjalizuje się w instalacji pompy ciepła i klimatyzatorów dla domów, mieszkań i przedsiębiorstw. Zajmujemy się kompleksową obsługą, począwszy od doradztwa technicznego, doboru odpowiedniego urządzenia, aż po montaż i serwis. Działamy na rynku od wielu lat i cieszymy się uznaniem wśród naszych klientów dzięki wysokiej jakości usług oraz indywidualnemu podejściu do każdego zlecenia."
      >
        <template v-slot:default>
          <div class="cards">
            <product-card :offsetImg="true" v-for="card in cards" :key="card" :card="card" />
          </div>
        </template>
      </section-badge>
    </template>
  </pages-badge>
</template>

<script>
import cardsArray from '../cards.js';
export default {
  computed: {
    cards() {
      return this.cardsFilter(cardsArray.cards, 'klimatyzator');
    },
  },
  methods:{
    cardsFilter(cards, type){
      return cards.filter((card => card.type === type))
    }
  }
};
</script>

<style scoped>
.cards {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
</style>
